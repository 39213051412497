<template>
  <info-modal :name="name" class="admin-info-modal">
    <template #title>관리 정보 입력 안내</template>
    <template #content>
      <p class="admin-info-modal__desc">
        관리 정보를 입력하지 않으면 설치가 지연될 수 있습니다. <br />
        입력하지 않은 정보는 추후 My가비아에서 입력하기 바랍니다. <br />
        계속 진행하시겠습니까?
      </p>
      <div class="admin-info-modal__buttons">
        <button class="gt-button gt-button-primary" @click="onApproveAdminInfoModal">예</button>
        <button class="gt-button gt-button-info" @click="onCancelAdminInfoModal">아니오</button>
      </div>
    </template>
  </info-modal>
</template>

<script>
import InfoModal from '@/components/common/InfoModal/InfoModal.vue';

export default {
  components: {
    InfoModal,
  },
  props: {
    name: {
      type: String,
    },
  },

  methods: {
    onApproveAdminInfoModal() {
      this.$emit('approve-admin-info-modal');
    },
    onCancelAdminInfoModal() {
      this.$GabiaModal.hide('admininfo');
    },
  },
};
</script>

<style lang="scss" scoped>
.admin-info-modal {
  &__desc {
    color: #666;
    text-align: left;
    font-size: 15px;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    margin-top: 40px;

    button {
      width: 100px;

      & + button {
        margin-left: 5px;
      }
    }
  }
}
</style>
