
import Vue from 'vue';
import BillLayout from '@/layouts/BillLayout.vue';
import FormLayout from '@/layouts/FormLayout.vue';
import TableLayout from '@/layouts/TableLayout.vue';
import ColumnLayout from '@/layouts/ColumnLayout.vue';
import EmailInput from '@/components/inputs/EmailInput.vue';
import CallNumberInput from '@/components/inputs/CallNumberInput.vue';
import AdminInfoMixins from '@/mixins/AdminInfoInputMixins';

import formTitle from '@/assets/data/formTitle/index.json';

export default Vue.extend({
  components: {
    FormLayout,
    BillLayout,
    TableLayout,
    ColumnLayout,
    EmailInput,
    CallNumberInput,
  },
  mixins: [AdminInfoMixins],
  model: {
    prop: 'formState',
    event: 'change',
  },
  props: {
    isShowCompanyNameInput: {
      type: Boolean,
      default: false,
    },
    formState: {
      type: Object,
      default() {
        return {
          companyName: String,
          owner: {
            id: String,
            name: String,
            email: String,
            phoneNumber: String,
            mobilePhoneNumber: String,
          },
          admin: {
            isSameOwner: Boolean,
            name: String,
            email: {
              emailId: String,
              emailDomain: String,
              email: String,
            },
            phoneNumber: {
              num1: String,
              num2: String,
              num3: String,
              num: String,
            },
            mobilePhoneNumber: {
              num1: String,
              num2: String,
              num3: String,
              num: String,
            },
          },
        };
      },
    },
  },
  data() {
    return {
      formTitle,
    };
  },
});
