import { proxyAPI } from '../instance';
import { PROXYALIAS } from '../types';

const proxyAlias = PROXYALIAS.payment;

interface SendPaymentSMSDataParams {
  ordernum: string;
  hp_no: string;
}

// 신규 주문서 생성 API
export const sendPaymentSMS = async function (paramsData: SendPaymentSMSDataParams) {
  const params = {
    url: '/api/payment/send/sms',
    method: 'POST',
    data: paramsData,
  };

  return await proxyAPI.post(`/auth/${proxyAlias}`, params);
};
