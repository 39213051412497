<template>
  <div class="virtual-screen-box">
    <div id="pay_finish_virtual_screen" class="calculator__area sticky">
      <p class="title"><i class="fal fa-usd-circle"></i> 결제 금액 계산</p>
      <div class="estimate-screen">
        <!-- 가상 계좌 -->
        <div class="calculator-wrap" v-if="orderInfo.pay_method === '3'">
          <div class="goods-container">
            <ul class="goods">
              <li class="goods__description long">
                <div class="title">주문번호</div>
                <div class="price">{{ orderInfo.ordernum }}</div>
              </li>
            </ul>
            <ul class="goods">
              <li class="goods__description">
                <div class="title">입금은행</div>
                <div class="price">{{ orderInfo.virtual_account.bank_name }}</div>
              </li>
            </ul>
            <ul class="goods">
              <li class="goods__description">
                <div class="title">입금계좌</div>
                <div class="price">{{ orderInfo.virtual_account.bank_number }}</div>
              </li>
            </ul>
            <ul class="goods">
              <li class="goods__description">
                <div class="title">예금주</div>
                <div class="price">(주)가비아</div>
              </li>
            </ul>
            <ul class="goods">
              <li class="goods__description">
                <div class="title">입금기한</div>
                <div class="price">{{ vaccountLimitDateString }}</div>
              </li>
            </ul>
          </div>
          <div class="total-pay">
            <p>결제 예상 금액</p>
            <p>
              <span class="pay">
                {{ orderInfo.total_price.toLocaleString() }}
                <span class="unit">원</span></span
              >
            </p>
          </div>
        </div>
        <!-- 무통장, 실제 결제를 타는 부분은 아닌 것으로 판단하지만 임시로 -->
        <div class="calculator-wrap" v-if="orderInfo.pay_method === '0'">
          <div class="goods-container">
            <ul class="goods">
              <li class="goods__description long">
                <div class="title">주문번호</div>
                <div class="price">{{ orderInfo.ordernum }}</div>
              </li>
            </ul>
            <ul class="goods">
              <li class="goods__description">
                <div class="title">입금은행</div>
                <div class="price">{{ orderInfo.virtual_account.bank_name }}</div>
              </li>
            </ul>
            <ul class="goods">
              <li class="goods__description">
                <div class="title">입금계좌</div>
                <div class="price">{{ orderInfo.virtual_account.bank_number }}</div>
              </li>
            </ul>
            <ul class="goods">
              <li class="goods__description">
                <div class="title">예금주</div>
                <div class="price">(주)가비아</div>
              </li>
            </ul>
            <ul class="goods">
              <li class="goods__description">
                <div class="title">입금기한</div>
                <div class="price">{{ vaccountLimitDateString }}</div>
              </li>
            </ul>
          </div>

          <div class="total-pay">
            <p>결제 예상 금액</p>
            <p>
              <span class="pay">
                {{ orderInfo.total_price.toLocaleString() }}
                <span class="unit">원</span></span
              >
            </p>
          </div>
        </div>
        <!-- 신용카드, @TODO authNo -->
        <div class="calculator-wrap" v-if="orderInfo.pay_method === '9'">
          <div class="goods-container">
            <ul class="goods">
              <li class="goods__description long">
                <div class="title">주문번호</div>
                <div class="price">{{ orderInfo.ordernum }}</div>
              </li>
            </ul>
            <ul class="goods">
              <li class="goods__description">
                <div class="title">결제금액</div>
                <div class="price">{{ orderInfo.total_price.toLocaleString() }}원</div>
              </li>
            </ul>
            <ul class="goods">
              <li class="goods__description">
                <div class="title">승인번호</div>
                <div class="price">{{ orderInfo.pay_tno }}</div>
              </li>
            </ul>
            <ul class="goods">
              <li class="goods__description">
                <div class="title">결제방법</div>
                <div class="price">{{ orderInfo.pay_method_name }}</div>
              </li>
            </ul>
          </div>
          <div class="total-pay">
            <p>결제 예상 금액</p>
            <p>
              <span class="pay">
                {{ orderInfo.total_price.toLocaleString() }}
                <span class="unit">원</span></span
              >
            </p>
          </div>
        </div>
        <!-- 실시간 계좌 이체 -->
        <div class="calculator-wrap" v-if="orderInfo.pay_method === '2'">
          <div class="goods-container">
            <ul class="goods">
              <li class="goods__description long">
                <div class="title">주문번호</div>
                <div class="price">{{ orderInfo.ordernum }}</div>
              </li>
            </ul>
            <ul class="goods">
              <li class="goods__description">
                <div class="title">결제금액</div>
                <div class="price">{{ orderInfo.total_price.toLocaleString() }}원</div>
              </li>
            </ul>
            <ul class="goods">
              <li class="goods__description">
                <div class="title">결제방법</div>
                <div class="price">{{ orderInfo.pay_method_name }}</div>
              </li>
            </ul>
          </div>
          <div class="total-pay">
            <p>결제 예상 금액</p>
            <p>
              <span class="pay">
                {{ orderInfo.total_price.toLocaleString() }}
                <span class="unit">원</span></span
              >
            </p>
          </div>
        </div>
        <button class="gt-button gt-button-primary btn" @click="onClickNextStep">다음 단계</button>
        <template v-if="orderInfo.pay_method === '3'">
          <button class="gt-button gt-button-info btn" @click="onClickOpenSMSModal">SMS 발송</button>
        </template>
        <template v-else-if="orderInfo.pay_method === '9'">
          <button class="gt-button gt-button-info btn" @click="onClickCardSaleSlip">카드 매출 전표</button>
        </template>
        <template v-else-if="orderInfo.pay_method === '2'">
          <button class="gt-button gt-button-info btn" @click="onClickCashReceipt">현금영수증 출력</button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import formTitle from '@/assets/data/formTitle/index.json';

export default Vue.extend({
  props: {
    orderInfo: {
      type: Object,
    },
  },
  data() {
    return {
      formTitle,
    };
  },
  computed: {
    vaccountLimitDateString() {
      if (!this.orderInfo.v_account_date) return;
      // IE를 위해서 .-를 /로 대체
      const applyDate = new Date(this.orderInfo.v_account_date.replace(/[.-]/gi, '/'));
      applyDate.setDate(applyDate.getDate() + 15);
      const limitDate = applyDate;
      return `${1900 + limitDate.getYear()}년 ${limitDate.getMonth() + 1}월 ${limitDate.getDate()}일`;
    },
  },
  methods: {
    onClickNextStep() {
      this.$emit('click-next-step');
    },
    onClickOpenSMSModal() {
      this.$emit('click-open-sms-modal');
    },
    onClickCardSaleSlip() {
      /*
        [카드 매출 전표 출력]
        4단계 정보 확인 / https://www.gabia.com/assets/js/payment/index.js / open_card_receipt_common 함수 참고
       */
      /*window.open("http://pgweb.dacom.net/pg/wmp/etc/jsp/Receipt_Link.jsp?mertid="+mertid+"&tid="+tid+"&authdata="+authdata,"eCreditReceipt","toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=no, width=450, height=600");*/
      const url = `https://www.gabia.com/payments/receipt?ordernum=${this.orderInfo.ordernum}`;
      window.open(url, 'card_receipt_window', 'menubar=0,toolbar=0,scrollbars=no,width=452,height=820');
    },
    onClickCashReceipt() {
      /*
        [현금영수증 출력]
        https://gitlab.gabia.com/hosting/message.gabia.com/-/blob/master/components/service_application/BillingVirtualScreen.vue 참고
       */
      const url = `https://admin.kcp.co.kr/Modules/Bill/ADSA_ACNT_N_Receipt.jsp?a_trade_no=${this.orderInfo.pay_tno}`;
      window.open(url, 'card_receipt_window', 'menubar=0,toolbar=0,scrollbars=no,width=452,height=820');
    },
  },
});
</script>
<style lang="scss" scoped>
.virtual-screen-box {
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
  grid-column: 2 / 3;
  grid-row: 2 / 3;

  @include mq('desktop-wide', 'max') {
    margin-top: 50px;
  }

  .calculator__area {
    font-size: 16px;
    @supports (position: sticky) or (position: -webkit-sticky) {
      &.sticky {
        position: -webkit-sticky; /* 사파리 브라우저 지원 */
        position: sticky;
        align-self: flex-start;
        top: calc(81px + 10px + 30px);
      }
    }
    width: 100%;
    > .title {
      text-align: left;
      padding: 0 0 20px;
      font-size: 18px;
      i {
        margin-right: 9px;
        font-size: 22px;
      }
    }
    .estimate-screen {
      box-sizing: border-box;
      padding: 30px 40px;
      border-radius: 3px;
      background: #f0f4f9;

      @include mq('desktop-wide', 'max') {
        padding: 20px 15px;
      }
    }
    .calculator-wrap {
      .goods-container {
        display: flex;
        flex-direction: column;
        .goods {
          &__description {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 15px 0;
            letter-spacing: -1px;
            font-weight: 300;
            &:last-child {
              border-bottom: 1px solid #e5e5e5;
            }
            &.long {
              flex-direction: column;
            }
            .title {
              max-width: 130px;
              text-align: start;
              color: #333;
              line-height: 1.5;
              font-weight: normal;
            }
            .price {
              display: flex;
              &.red {
                color: var(--red);
              }
              &-unit {
                margin-left: 2px;
              }
            }
            &--add {
              font-size: 14px;
              padding-top: 0;
              font-weight: 300;
              .title {
                font-weight: 400;
              }
            }
          }
        }
      }
      .total-pay {
        padding: 60px 0 20px;
        font-size: 14px;
        text-align: right;
        p:first-child {
          font-weight: 400;
          color: var(--blue);
        }
        .pay {
          color: var(--blue);
          font-size: 30px;
          font-weight: bold;
        }
      }
    }

    .btn {
      width: 100%;
      & + .btn {
        margin-top: 10px;
      }
    }
  }
}
</style>
