
import Vue, { PropType } from 'vue';
import MicrosoftStep03Template from '@/templates/ms365/MicrosoftStep03Template.vue';
import AdminInfoModal from '@/components/modals/AdminInfoModal.vue';
import { sendPaymentSMS } from '@/api/proxy/payment';
import formTitle from '@/assets/data/formTitle/index.json';
import EstimateScreen from '@/components/common/EstimateScreen.vue';
import InfoModal from '@/components/common/InfoModal/InfoModal.vue';
import CallNumberInput from '@/components/inputs/CallNumberInput.vue';
import { moveToFinalPage } from '@/utils';
import { getUserDetail, updateManagerInfo } from '@/api/proxy/gapi';
import { OrderInfo } from '@/api/proxy/order/types';
import { REGEX_PHONE_NUMBER, REGEX_EMAIL, REGEX_MOBILE_NUMBER } from '@/utils/regex';

export default Vue.extend({
  components: {
    MicrosoftStep03Template,
    AdminInfoModal,
    EstimateScreen,
    InfoModal,
    CallNumberInput,
  },
  props: {
    orderInfo: Object as PropType<OrderInfo>,
  },
  data() {
    return {
      formTitle,
      isShowCompanyNameInput: true,
      formState: {
        companyName: '',
        // 소유자 정보
        owner: {
          id: '',
          name: '',
          email: '',
          phoneNumber: '',
          mobilePhoneNumber: '',
        },
        // 관리자 정보
        admin: {
          isSameOwner: false,
          name: '',
          email: {
            id: '',
            domain: '직접 입력',
            text: '',
          },
          phoneNumber: {
            num1: '02',
            num2: '',
            num3: '',
          },
          mobilePhoneNumber: {
            num1: '010',
            num2: '',
            num3: '',
          },
        },
      },
      isEdit: false,
      // SMS 발송내 휴대전화 정보
      infoModalMobilePhoneNumber: {
        num1: '010',
        num2: '',
        num3: '',
      },
    };
  },
  async created() {
    const getUser = await getUserDetail();

    this.formState.owner = {
      id: getUser?.user_id || '',
      name: getUser?.register || '',
      email: getUser?.email || '',
      phoneNumber: getUser?.phone || '',
      mobilePhoneNumber: getUser?.hp_no || '',
    };

    // SMS 발송내 휴대전화 정보
    const getUserHpNo = this.formState.owner.mobilePhoneNumber?.split('-');

    this.infoModalMobilePhoneNumber = {
      num1: getUserHpNo?.[0] || '010',
      num2: getUserHpNo?.[1] || '',
      num3: getUserHpNo?.[2] || '',
    };

    if (this.orderInfo) {
      // 기업 회원인 경우
      if (this.$store.state.userInfo.user_type === 'organization') {
        this.formState.companyName = this.$store.state.userInfo.user_name
          .replace(/(?:\(|<).*?(?:\)|>)/g, '')
          .replace(/\s+/g, '')
          .replace(/[^0-9a-zA-Z가-힣]/g, '')
          .trim();
      }
    }

    this.$GlobalLoading.hide();
  },
  methods: {
    async onApproveAdminInfoModal() {
      this.$GabiaModal.hide('sms');

      const { companyName } = this.formState;
      const service_seqno_list = this.orderInfo.settlement_list.map((settlement) => settlement.service_seqno);
      const results = [] as boolean[];

      for (const service_seq of service_seqno_list) {
        const params = {
          service_seq,
          b_hanname: this.formState.admin.name,
          b_email: '',
          b_phone: '',
          b_mobile: '',
          company_name: this.isShowCompanyNameInput ? companyName : '',
        };

        if (this.formState.admin.email.id !== '') {
          if (this.formState.admin.email.domain === '직접 입력') {
            params.b_email = `${this.formState.admin.email.id}@${this.formState.admin.email.text}`;
          } else {
            params.b_email = `${this.formState.admin.email.id}@${this.formState.admin.email.domain}`;
          }
        }

        if (this.formState.admin.phoneNumber.num2 !== '') {
          params.b_phone = [
            this.formState.admin.phoneNumber.num1,
            this.formState.admin.phoneNumber.num2,
            this.formState.admin.phoneNumber.num3,
          ].join('-');
        }

        if (this.formState.admin.mobilePhoneNumber.num2 !== '') {
          params.b_mobile = [
            this.formState.admin.mobilePhoneNumber.num1,
            this.formState.admin.mobilePhoneNumber.num2,
            this.formState.admin.mobilePhoneNumber.num3,
          ].join('-');
        }

        const managerInfo = await updateManagerInfo(params);
        results.push(managerInfo?.isSuccess || false);
      }

      if (results.filter((result: boolean) => !result).length > 0) {
        alert('관리자 정보가 정상 처리되지 않았습니다. 마이가비아에서 관리 정보를 입력하세요.');
      } else {
        alert('관리자 정보가 처리 완료되었습니다.');
      }

      this.$GlobalLoading.hide();

      moveToFinalPage({
        ordernum: this.orderInfo.ordernum,
      });
    },
    onClickNextStep() {
      const { admin, companyName } = this.formState;
      const { name, email, mobilePhoneNumber, phoneNumber, isSameOwner } = admin;

      const namePattern = /^[가-힣]{3,40}$/;
      const companyPattern = /^[a-zA-Z0-9가-힣]{2,20}$/;

      if (this.isShowCompanyNameInput && companyName.length === 0) {
        this.$GabiaModal.show('admininfo');
        return;
      }

      //! 소유자 정보와 같더라도 꼭 입력한 값을 사용하도록 개선

      // 다음 단계 - 소유자 정보와 같음 -> modal 보여주지 않고 바로 실행
      // if (isSameOwner) {
      //   this.$GlobalLoading.show();
      //   this.onApproveAdminInfoModal();
      // } else {
      // 이메일
      if (
        email.id.length > 0 &&
        ((email.domain === '직접 입력' && !REGEX_EMAIL.test(email.id + '@' + email.text)) ||
          (email.domain !== '직접 입력' && !REGEX_EMAIL.test(email.id + '@' + email.domain)))
      ) {
        alert('잘못된 이메일 형식입니다.');
        return;
      }

      // 관리자명
      if (!namePattern.test(name)) {
        alert('관리자명은 3자 이상 한글만 입력해주세요. 영문자, 숫자, 공백 및 특수문자는 허용되지 않습니다.');
        return;
      }

      // 관리자명
      if (!companyPattern.test(companyName)) {
        alert('회사명은 2자 이상 입력해주세요. 공백 및 특수문자는 허용되지 않습니다. ');
        return;
      }

      // 전화번호
      if (
        (phoneNumber.num2.length > 0 || phoneNumber.num3.length > 0) &&
        !REGEX_PHONE_NUMBER.test([phoneNumber.num1, phoneNumber.num2, phoneNumber.num3].join('-'))
      ) {
        alert('잘못된 전화번호 형식입니다.');
        return;
      }

      // 휴대전화
      if (
        (mobilePhoneNumber.num2.length > 0 || mobilePhoneNumber.num3.length > 0) &&
        !REGEX_MOBILE_NUMBER.test([mobilePhoneNumber.num1, mobilePhoneNumber.num2, mobilePhoneNumber.num3].join('-'))
      ) {
        alert('잘못된 휴대전화 형식입니다.');
        return;
      }

      // 관리자 정보를 모두 입력한 경우
      if (
        name.length > 0 &&
        // 이메일
        email.id.length > 0 &&
        ((email.domain === '직접 입력' && REGEX_EMAIL.test(email.id + '@' + email.text)) ||
          (email.domain !== '직접 입력' && REGEX_EMAIL.test(email.id + '@' + email.domain))) &&
        REGEX_PHONE_NUMBER.test([phoneNumber.num1, phoneNumber.num2, phoneNumber.num3].join('-')) &&
        REGEX_MOBILE_NUMBER.test([mobilePhoneNumber.num1, mobilePhoneNumber.num2, mobilePhoneNumber.num3].join('-'))
      ) {
        this.$GlobalLoading.show();
        this.onApproveAdminInfoModal();
      } else {
        this.$GabiaModal.show('admininfo');
      }
      //}
    },
    onClickOpenSMSModal() {
      this.$GabiaModal.show('sms');
    },
    onEditSms() {
      this.isEdit = !this.isEdit;
    },
    onChangeMobilePhoneNumber(e) {
      const { name, value } = e;

      this.infoModalMobilePhoneNumber = {
        ...this.infoModalMobilePhoneNumber,
        [name]: value,
      };
    },
    onCloseModal() {
      this.isEdit = false;

      const getUserHpNo = this.formState.owner.mobilePhoneNumber?.split('-');

      this.infoModalMobilePhoneNumber = {
        num1: getUserHpNo?.[0] || '010',
        num2: getUserHpNo?.[1] || '',
        num3: getUserHpNo?.[2] || '',
      };
    },
    async onSendSms() {
      const { num1, num2, num3 } = this.infoModalMobilePhoneNumber;
      const hp_no = `${num1}-${num2}-${num3}`;

      if (!REGEX_PHONE_NUMBER.test(hp_no)) {
        alert('올바르지 않은 휴대전화 번호 형식입니다.');
      } else {
        await sendPaymentSMS({
          ordernum: this.orderInfo.ordernum,
          hp_no,
        });
        alert('SMS 발송이 정상 처리되었습니다.');
        this.$GabiaModal.hide('sms');
        this.onCloseModal();
      }
    },
  },
});
